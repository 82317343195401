<template>
  <div class="row">
    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <img src="/images/sorry.png" style="width: 250px;" class="" />
          </div>
          <p class="font-weight-bold" style="font-size: 1.3rem">
            Based on your answers we are unable to provide a flu voucher.
          </p>
          <p>
            We advise that you contact your GP clinic to organise a flu
            vaccination.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>